!(function(global){
  'use strict';

  function checkFilter(companies, filter, selection){

    for (var i = 0; i < companies.length; i++) {
      var dataAttrs = $(companies[i]).data(filter);
      var inArray = false;

      for (var j = 0; j < dataAttrs.length; j++) {
        if(dataAttrs[j] === selection) inArray = true;
      }

      if(!inArray) {
        $(companies[i]).hide();
      }
    }

    var hiddenCompanies = $('.award-section.is-active .award-company:hidden').length;

    if (hiddenCompanies === companies.length && $('.no-companies').length < 1) {
      $('<p class="no-companies">No companies found</p>').insertAfter('form.custom');
    }

    if (hiddenCompanies !== companies.length) {
      $('.no-companies').remove();
    }
  };

  $(document).ready(function() {

    if ($('form.custom').length) {
      $(document).foundation();
    }

    $('.award-list__nav li').on('click', function() {
      var category = $(this).data('award');
      var $selectedSection = $(this).closest('.award-list__nav').siblings('.award-list__content').find('[data-award="' + category + '"]');
      var $hiddenSection = $selectedSection.siblings('section');

      // toggle sections
      if ($selectedSection.is(':hidden')) {
        $hiddenSection.hide().removeClass('is-active');
        $selectedSection.show().addClass('is-active');
      }

      // toggle tabs
      if (!$(this).hasClass('is-selected')) {
        $(this).addClass('is-selected');
        $(this).siblings('li').removeClass('is-selected');
      }

      if ($(window).width() < 769) {
        $('html, body').animate({
          scrollTop: $('#award-companies').offset().top + 50
        }, 1000);
      }

      $('.award-list__winners').hasClass('is-active') ? $('.award-bottom').show() : $('.award-bottom').hide();
    });

    // filter functionality for Top 100 awards section
    $('.award-filter').on('change', function(){
      var sectorSelection = $('#sector-select').val();
      var regionSelection = $('#region-select').val();

      var sectorFilter = $('#sector-select').attr('name');
      var regionFilter = $('#region-select').attr('name');

      var companies = $('.award-section.is-active').find('.award-company');

      for (var i = 0; i < companies.length; i++) {
        $(companies[i]).show();
      }

      if (regionSelection !== 'All' && regionSelection !== undefined) {
        checkFilter(companies, regionFilter, regionSelection);
      } else if (sectorSelection === 'All' && regionSelection === 'All') {
        $('.no-companies').remove();
      }

      if (sectorSelection !== 'All' && sectorSelection !== undefined) {
        checkFilter(companies, sectorFilter, sectorSelection);
      } else if (sectorSelection === 'All' && regionSelection === 'All') {
        $('.no-companies').remove();
      }
    });

    $('body').on('click', 'a.current', function(e) {
      e.preventDefault();

      $(this).siblings('ul').toggleClass('is-active');
    });

    $('body').on('click', '.award-filter li', function() {
      $(this).parent('ul').toggleClass('is-active');
    });

    $('#company-view-roles').on('click', function() {
      $('html, body').animate({
        scrollTop: $('#company-jobs-title').offset().top
      }, 1000);
    });

    $('#what-we-offer-cta').on('click', function(e) {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: $('#what-we-offer').offset().top
      }, 1000);
    });

    $('.show-modal').on('click', function(e){
      e.preventDefault();

      var modal = $(this).data('modal');

      $(this).closest('body').find('.custom-modal__background[data-modal="' + modal + '"]').addClass('is-active');
      $('body').addClass('is-disabled');
    });

    $('.close-modal').on('click', function(e){

      if (e.target !== this) return;

      $('.custom-modal__background').removeClass('is-active');
      $('body').removeClass('is-disabled');
    });

    $(window).on('resize', function(){
      if (window.innerWidth < 768) {
        $('#top100Banner').attr('src', '/wp-content/themes/gcc/images/square-banner.jpg');
        addIconsToMobileMenu();
      } else {
        $('#top100Banner').attr('src', '/wp-content/themes/gcc/images/long-banner.jpg');
      }
    });

    $('.wwo-list li').on('click', function(e) {
      if ($(window).innerWidth() >= 1024 && !$(this).hasClass('is-active')) {
        $('.wwo-list li').removeClass('is-active');
        $('.wwo-content').removeClass('is-active');

        $(this).parent().addClass('is-active');
        $(this).addClass('is-active');
        $(this).find('.wwo-content').addClass('is-active');

        $('body').animate({
          scrollTop: $(this).find('.wwo-content').offset().top - 50
        }, 600);
      }

      if ($(window).innerWidth() < 1024) {
        $(this).find('.wwo-content').slideToggle();
        $(this).toggleClass('is-active');
      }

    });

    function addIconsToMobileMenu() {
      if (!$('.mobile-header-nav .menu-item-has-children').find('.fa').length) {
        $('.mobile-header-nav .menu-item-has-children').append('<i class="fa fa-plus"></i>');
      }
    }

    addIconsToMobileMenu();

    $('.main_content').on('click', '.menu-item-has-children .fa', function() {
      $(this).siblings('.submenu').slideToggle();

      if ($(this).hasClass('fa-plus')) {
        $(this).removeClass('fa-plus').addClass('fa-minus');
      } else {
        $(this).addClass('fa-plus').removeClass('fa-minus');
      }
    });

    $('#js-arrow-top').on('click', function() {
      $('html, body').animate({
        scrollTop: 0
      }, 1000);
    });

    $('.cia-footer-nav-column h3').on('click', function() {
      if ($(window).width() < 900) {
        $(this).toggleClass('is-active');
        $(this).siblings('ul').slideToggle();
      }
    });

  });
})(window);
